<template>
    <div class="group-tag-strom-page" @click.stop="cklickModal(false)" v-if="tagModalShow">
      <div @click.stop="cklickModal(true)" class="group-tag-strom-page-center">
        <div @click.stop="cklickModal(false)" class="close-modal">
          <a-icon type="close" />
        </div>
        <div class="group-tag-strom-detail-list">
          <div class="group-tag-strom-detail-name">
            {{getString(strings.Mind_Group_Apply_To_Join_Sharing_Group) }}
          </div>
          <div class="group-tag-strom-title">
            {{ groupItem.name }}
          </div>

        </div>
        <div>
          <textarea class="group-tag-strom-detail-input" v-model="groupTagName" @input="inputArea()"
            v-on:keyup.enter="affirm()" :placeholder="getString(strings.Reasons_For_Application_0_30_Words)">
                  </textarea>
          <div class="tag-warning">
            {{warning}}
          </div>
        </div>
        <div class="group-tap-footer">
          <!-- <div @click.stop="cancel()" class="group-tap-footer-button">
                      {{getString(strings.Mind_Group_Cancel) }}
                  </div> -->
          <div @click.stop="affirm()" class="group-tap-footer-button">
            {{getString(strings.Mind_Group_Send_Out) }}
            <!-- 发送 -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";

export default {
  props:['tagModalShow','groupItem'],
  data() {
    return {
        strings, //语言包
        groupTagName: '', //申请理由
        warning: '',
    };
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    cklickModal(val) {
        if (val == false) { //当隐藏模态框时
            this.groupTagName = ''
            this.warning = ''
            this.$emit('hideModal',2);
        }
    },
    affirm() {
        if (this.trim(this.groupTagName) == '') {
            this.warning = getString(strings.Mind_Group_The_Content_Cannot_Be_Empty)
            return
        }
        this.$emit('applyJoinGroup',this.groupTagName);
        this.cklickModal(false);
    },
    cancel() {
        this.cklickModal(false);
    },
    trim(str) { //判断有首尾空格去除空格
        return str.replace(/^\s+|\s+$/g, '');
    },
    inputArea() { //不允许输入或黏贴超过20个字符
        this.warning = ''
        if (this.groupTagName.length > 30) {
            this.groupTagName = this.groupTagName.slice(0, 30);
        }
    },
  },
  created() {
    
  },

  components: {
  },
};
</script>

<style lang="less" scoped>
.group-tag-strom-page {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .2);
    z-index: 6000;

    .group-tag-strom-page-center {
      position: absolute;
      width: 325px;
      min-height: 325px;
      padding: 20px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 4px;

      .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 16px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        color: #333;
        border-radius: 0 10px 0 0;
        transition: 0.3s;
      }

      .close-modal:hover {
        transition: 0.3s;
        color: #fd492b;
        transform: rotate(180deg);
      }

      .group-tag-strom-detail-list {
        width: 230px;
        font-size: 14px;
        // line-height: 40px;
        color: #333333;
        margin: 0 auto;
        margin-bottom: 20px;
        text-align: center;

        .group-tag-strom-detail-name {
          // margin-right: 20px;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .group-tag-strom-title {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #999999;
        }

      }

      .group-tag-strom-detail-input {
        width: 100%;
        // width: 230px;
        height: 142px;
        background-color: #F2F2F2;
        outline: none;
        border: 0;
        resize: none; //禁止拉伸
        border-radius: 4px;
        font-size: 14px;
        padding: 15px;

        &::placeholder {
          font-size: 14px;
          color: #999999;
          // position: absolute;
          // top: 16px;
          // left: 16px;
        }
      }

      .tag-warning {
        // text-align: center;
        color: #FD492B;
        padding: 0 5px;
      }

      .group-tap-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .group-tap-footer-button {
          padding: 0 12px;
          width: 100%;
          height: 44px;
          line-height: 44px;
          text-align: center;
          border-radius: 26px;
          font-size: 15px;
          background-color: #FD492B;
          color: #fff;
          cursor: pointer;
        }

      }
    }
}
</style>
